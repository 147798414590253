import React, { useState, useEffect, useRef, useMemo } from "react";
import {
    useGetJobQtyAgentQuery,
} from "../store/apiSlice";
import { useSelector, useDispatch } from "react-redux";
const JobLoadingActivity = () => {
    const { data, isLoading, isSuccess } = useGetJobQtyAgentQuery();

    function formattedPercentage(numerator, denominator) {
        const percentage = Math.floor((numerator / denominator) * 100);
        const formattedPercentage = isNaN(percentage) ? '0%' : `${percentage}%`;
        return formattedPercentage
    }
    return (
        <div className="max-w-4xl  mx-auto table-auto  flex flex-col gap-4 justify-center items-center">
            <div className="  bg-slate-200 p-10 rounded-xl mb-8">
                <div>
                    <h1 className="lg:text-5xl font-bold  text-center text-amber-500">
                        Job Loading Activity<br />
                    </h1>
                    <p className="text-blue-800 text-3xl font-bold text-center pt-2">"Top performers get recognised."</p>
                </div>
            </div>
            <p className="text-2xl underline font-extrabold">Jobs Loading Activity</p>
            <table className="border border-collapse border-gray-400">
                <thead>
                    <tr>
                        <th className="border p-2">Agent</th>
                        <th className="border p-2">Today</th>
                        <th className="border p-2">Today Leads</th>
                        <th className="border p-2">3 Days</th>
                        <th className="border p-2">3 Days Leads</th>
                        <th className="border p-2">7 Days</th>
                        <th className="border p-2">7 Days Leads</th>
                        <th className="border p-2">7 Business Days</th>
                        <th className="border p-2">7 Business Days Leads</th>
                        <th className="border p-2">30 days</th>
                        <th className="border p-2">30 days Leads</th>

                    </tr>
                </thead>
                {data?.list &&
                    <tbody>
                        <tr key={-1} className="border">
                            <td className="border p-2">{`TOTAL`}</td>
                            <td className="border p-2">{data.total}</td>
                            <td className="border p-2">{data.totalTodayLeads}</td>
                            <td className="border p-2">{data.total3Days}</td>
                            <td className="border p-2">{data.total3DaysLeads}</td>
                            <td className="border p-2">{data.total7Days}</td>
                            <td className="border p-2">{data.total7DaysLeads}</td>
                            <td className="border p-2">{data.total7BusinessDays}</td>
                            <td className="border p-2">{data.total7BusinessDaysLeads}</td>
                            <td className="border p-2">{data.total30days}</td>
                            <td className="border p-2">{data.total30daysLeads}</td>


                        </tr>
                        <tr key={-1} className="border">
                            <td className="border p-2">{`AVERAGE`}</td>
                            <td className="border p-2">{data.totalAverage}</td>
                            <td className="border p-2">{data.totalTodayLeadsAverage}</td>
                            <td className="border p-2">{data.total3DaysAverage}</td>
                            <td className="border p-2">{data.total3DaysLeadsAverage}</td>
                            <td className="border p-2">{data.total7DaysAverage}</td>
                            <td className="border p-2">{data.total7DaysLeadsAverage}</td>
                            <td className="border p-2">{data.total7BusinessDaysAverage}</td>
                            <td className="border p-2">{data.total7BusinessDaysLeadsAverage}</td>
                            <td className="border p-2">{data.total30daysAverage}</td>
                            <td className="border p-2">{data.total30daysLeadsAverage}</td>


                        </tr>
                        {data.list.map(({ postedBy, firstName, job7BusinessDays, job7BusinessDaysHasEmail,lastName, jobToday, jobTodayHasEmail, job3daysHasEmail, job7daysHasEmail, job3days, job7days, job30days, job30daysHasEmail, headlineOnly }, i) => (
                            <tr key={i} className="border">
                                <td className="border p-2">{`${firstName} ${lastName ?lastName : ''}`}</td>
                                <td className="border p-2">{jobToday}</td>
                                <td className="border p-2">{jobTodayHasEmail}</td>
                                <td className="border p-2">{job3days}</td>
                                <td className="border p-2">{job3daysHasEmail}</td>
                                <td className="border p-2">{job7days}</td>
                                <td className="border p-2">{job7daysHasEmail}</td>
                                <td className="border p-2">{job7BusinessDays}</td>
                                <td className="border p-2">{job7BusinessDaysHasEmail}</td>
                                <td className="border p-2">{job30days}</td>
                                <td className="border p-2">{job30daysHasEmail}</td>
                            </tr>
                        ))}
                    </tbody>}
            </table>
        </div>
    )
};
export default JobLoadingActivity;
